import * as React from 'react'
import {
    selectConfig,
    selectEpoch,
    selectMainConfig,
    selectTimeZone,
    setStatus,
    storeModuleName,
    updateNowTime,
} from '../../features/core/coreSlice'
import { ToolbarContainer } from '../../components/toolbarComponent/toolbarComponentStyles'
import { ContainerWrapper } from '../../components/containerWrapper/containerWrapper'
import { enableNetworkAssets, loadIncidents } from '../../features/mapbox/mapboxSlice'
import { changeHorizon } from '../../features/horizonSlider/horizonSilderSlice'
import { getMarks } from '../../helpers/SliderMarksHelper'
import { loadMapData, loadMapDataByViewMode } from '../../features/core/mapData/mapDataSlice'
import HorizonSlider from '../../features/horizonSlider/horizonSlider'
import MapboxContainer from '../../features/mapbox/mapboxContainer'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getAllViewMode } from '../../helpers/ContainerHelper'
import Navigator from '../../features/navigator/Navigator'
import Pattern from '../../features/pattern/pattern'
import Sidebar from '../../features/sidebar/sidebar'
import { StyledDivider } from '../../components'
import Clock from '../../features/clock/clock'
import Views from '../../features/views/views'
import Kpis from './components/kpi/kpis/kpis'

import { useEffect, useState } from 'react'
import Logo from '../../features/logo/Logo'
import {
    loadDataRange, loadMonthAvailableData,
    selectAvailableData,
    selectMaxAndMinTimesWithData,
} from '../dashboardContainer/DashboardContainerSlice'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'

const AnalyzeContainer: React.FC<IContainerProps> = ({ options }: IContainerProps) => {
    const _moduleConfig: IModuleConfig = useAppSelector(selectConfig)
    const _mainConfig: IMainConfig = useAppSelector(selectMainConfig)
    const _epoch: number = useAppSelector(selectEpoch)
    const _timeZone: string = useSelector(selectTimeZone)
    const _availableData = useAppSelector(selectAvailableData)
    const _availableMaxAndMinTimesWithData: any = useAppSelector(selectMaxAndMinTimesWithData)
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(true)
    const [availableDatesAndTimes, setAvailableDatesAndTimes] = useState<AvailableDatesAndTimes>({})
    const dispatch = useAppDispatch()

    const horizonStep: number = _moduleConfig['horizon-step']
    const numberOfHorizon: number = _moduleConfig['horizon-count']
    const clockInterval: number = Number.parseInt(_mainConfig.defaults['clock-interval']) / 60

    const containerName = 'analyze'
    const marks = getMarks(horizonStep, numberOfHorizon)
    const allViewModes = getAllViewMode(containerName, _moduleConfig.view_mode, _moduleConfig.modules)

    useEffect(() => {
        setStatus('loading')
        dispatch(enableNetworkAssets({ networks: 'all', mapHorizon: 'default' }))
        dispatch(updateNowTime())
        dispatch(storeModuleName(containerName))
        dispatch(loadDataRange())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (_epoch !== 0) {
            dispatch(changeHorizon(0))
            getMapDataByEpoch(_epoch)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_epoch, _timeZone])

    useEffect(() => {
        if (_availableData.length > 0) {
            const _availableDatesAndTimes: any = {}
            _availableData.forEach((t: number) => {
                const currentTime = DateTime.fromMillis(t, { zone: _timeZone })
                const date = currentTime.toISODate() || ''
                const hour = currentTime.get('hour')
                const minute = currentTime.get('minute')
                if (!_availableDatesAndTimes[date]) {
                    _availableDatesAndTimes[date] = {}
                }
                if (!_availableDatesAndTimes[date][hour]) {
                    _availableDatesAndTimes[date][hour] = []
                }
                _availableDatesAndTimes[date][hour].push(minute)
            })
            setAvailableDatesAndTimes(_availableDatesAndTimes)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_availableData])

    useEffect(() => {
        const clockDate = DateTime.fromMillis(_epoch, { zone: _timeZone })
        dispatch(
            loadMonthAvailableData({
                month: clockDate.month,
                year: clockDate.year,
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_availableMaxAndMinTimesWithData])

    const getMapDataByEpoch = (epoch: number) => {
        dispatch(loadIncidents({ epoch, type: 'all' }))
        if (epoch !== 0) {
            dispatch(loadMapData({ epoch: _epoch, allViewMode: allViewModes }))
        }
    }

    const handleChangeHorizonAndDate = (horizon: number) => {
        dispatch(changeHorizon(horizon))
    }

    const handleChangeViewMode = (epoch: number, viewModeId: number, mapId: number) => {
        dispatch(loadMapDataByViewMode({ epoch, allViewMode: allViewModes, viewModeId: viewModeId, mapId: mapId, qm: false }))
    }

    const toggleSidebar = (open: boolean): void => {
        setSidebarOpen(!open)
    }
    const updateDataWithNowTime = false

    return (
        <ContainerWrapper>
            <ToolbarContainer>
                <Logo mode={sidebarOpen ? '' : 'compact'} />
                <Navigator config={_moduleConfig} />
                <StyledDivider orientation='vertical' />
                <Clock
                    minutesStep={clockInterval}
                    showArrowProgress={true}
                    allowPast={true}
                    longTimeStep={{ number: 1, unit: 'hours' }}
                    updateDataWithNowTime={updateDataWithNowTime}
                    availableDatesAndTimes={availableDatesAndTimes}
                    availableData={_availableData}
                    availableMaxAndMinTimesWithData={_availableMaxAndMinTimesWithData}
                    disabledNoAvailableDate={true}
                />
                <StyledDivider orientation='vertical' />
                <HorizonSlider
                    isDisabled={false}
                    horizonValue={0}
                    marks={marks}
                    horizonStep={horizonStep}
                    changeHorizonAndDate={handleChangeHorizonAndDate}
                />
                <StyledDivider orientation='vertical' />
                <Kpis
                    isDisabled={false}
                    dateFormat={_moduleConfig.date_format.dateTime}
                    unitSystem={_moduleConfig.units}
                />
                <StyledDivider orientation='vertical' />
                <Pattern showName={false} />
                <StyledDivider orientation='vertical' />
                <Views />
            </ToolbarContainer>
            <Sidebar
                open={sidebarOpen}
                toggleSidebar={toggleSidebar}
                epoch={_epoch}
                options={options}
                containerName={containerName}
            />
            <MapboxContainer
                layers={{
                    network: ['detectors', 'motorways', 'suburban', 'urban', 'local'],
                    incidents: ['external', 'test', 'real', 'detected'],
                    actions: [],
                }}
                sidebarOpen={sidebarOpen}
                containerName={containerName}
                changeViewMode={handleChangeViewMode}
            />
        </ContainerWrapper>
    )
}

export default AnalyzeContainer
