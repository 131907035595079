import { DateTime } from 'luxon'
import { SMOKE } from '../../theme'
import { t } from 'i18next'

export const HEATMAP_DAYS = 14
export const MAX_DETECTORS_TO_SHOW = 60
export const PATTERNS_PALETTE = [
    '#09254c',
    '#ffc303',
    '#00968B',
    '#7F37C2',
    '#5da5da',
    '#4aec71',
    '#faa43a',
    '#f15854',
    '#f459c6',
    '#7df8ba',
    '#fda290',
    '#b5f374',
    '#8383fb',
    '#c7bbf6',
    '#5d54fb',
    '#FFFF00',
    '#fdb0c3',
]

export const addTranslatableNoData = (noDataLabel: string) => {
    return {
        from: -1,
        to: -1,
        name: noDataLabel,
        color: '#dbdbdb',
    }
}
export const colorScaleStatisticData = {
    ranges: [
        {
            from: 91,
            to: 1000,
            name: '100%',
            color: '#290500',
        },
        {
            from: 81,
            to: 90.99999,
            name: '90%',
            color: '#6F0D00',
        },
        {
            from: 71,
            to: 80.99999,
            name: '80%',
            color: '#A71502',
        },
        {
            from: 61,
            to: 70.99999,
            name: '70%',
            color: '#EF4421',
        },
        {
            from: 51,
            to: 60.99999,
            name: '60%',
            color: '#FF8E01',
        },
        {
            from: 41,
            to: 50.99999,
            name: '50%',
            color: '#FFC403',
        },
        {
            from: 31,
            to: 40.99999,
            name: '40%',
            color: '#D7F095',
        },
        {
            from: 21,
            to: 30.99999,
            name: '30%',
            color: '#7FE7C2',
        },
        {
            from: 11,
            to: 20.99999,
            name: '20%',
            color: '#33BD8C',
        },
        {
            from: 0,
            to: 10.99999,
            name: '10%',
            color: '#00968B',
        },
    ],
}

export const colorScaleRealData = {
    ranges: [
        {
            from: 91,
            to: 1000,
            name: '100%',
            color: '#00968B',
        },
        {
            from: 81,
            to: 90.99999,
            name: '90%',
            color: '#33BD8C',
        },
        {
            from: 71,
            to: 80.99999,
            name: '80%',
            color: '#7FE7C2',
        },
        {
            from: 61,
            to: 70.99999,
            name: '70%',
            color: '#D7F095',
        },
        {
            from: 51,
            to: 60.99999,
            name: '60%',
            color: '#FFC403',
        },
        {
            from: 41,
            to: 50.99999,
            name: '50%',
            color: '#FF8E01',
        },
        {
            from: 31,
            to: 40.99999,
            name: '40%',
            color: '#EF4421',
        },
        {
            from: 21,
            to: 30.99999,
            name: '30%',
            color: '#A71502',
        },
        {
            from: 11,
            to: 20.99999,
            name: '20%',
            color: '#6F0D00',
        },
        {
            from: 0,
            to: 10.99999,
            name: '10%',
            color: '#290500',
        },
    ],
}

export const colorScaleR2 = {
    ranges: [
        {
            from: 96,
            to: 150,
            name: '> 0.95',
            color: '#00968B',
        },
        {
            from: 91,
            to: 95,
            name: '0.90 - 0.95',
            color: '#33BD8C',
        },
        {
            from: 86,
            to: 90,
            name: '0.85 - 0.90',
            color: '#7FE7C2',
        },
        {
            from: 81,
            to: 85,
            name: '0.80 - 0.85',
            color: '#D7F095',
        },
        {
            from: 76,
            to: 80,
            name: '0.75 - 0.80',
            color: '#FFC403',
        },
        {
            from: 71,
            to: 75,
            name: '0.70 - 0.75',
            color: '#FF8E01',
        },
        {
            from: 0,
            to: 70,
            name: '< 0.70',
            color: '#EF4421',
        },
    ],
}

export const colorScaleSlope = {
    ranges: [
        {
            from: 0,
            to: 70,
            name: '0 - 0.70',
            color: '#A71502',
        },
        {
            from: 71,
            to: 75,
            name: '0.70 - 0.75',
            color: '#EF4421',
        },
        {
            from: 76,
            to: 80,
            name: '0.75 - 0.80',
            color: '#FF8E01',
        },
        {
            from: 81,
            to: 85,
            name: '0.80 - 0.85',
            color: '#FFC403',
        },
        {
            from: 86,
            to: 90,
            name: '0.85 - 0.90',
            color: '#D7F095',
        },
        {
            from: 91,
            to: 95,
            name: '0.90 - 0.95',
            color: '#7FE7C2',
        },
        {
            from: 96,
            to: 105,
            name: '0.95 - 1.05',
            color: '#33BD8C',
        },
        {
            from: 106,
            to: 110,
            name: '1.05 - 1.10',
            color: '#7FE7C2',
        },
        {
            from: 111,
            to: 115,
            name: '1.10 - 1.15',
            color: '#D7F095',
        },
        {
            from: 116,
            to: 120,
            name: '1.15 - 1.20',
            color: '#FFC403',
        },
        {
            from: 121,
            to: 125,
            name: '1.20 - 1.25',
            color: '#FF8E01',
        },
        {
            from: 126,
            to: 130,
            name: '1.25 - 1.30',
            color: '#EF4421',
        },
        {
            from: 130,
            to: 99999,
            name: '1.30 - inf',
            color: '#A71502',
        },
    ],
}

export const heatmapOptionsInitialState: ApexCharts.ApexOptions = {
    tooltip: {},
    chart: {
        animations: {
            enabled: false,
            animateGradually: {
                enabled: false,
            },
            dynamicAnimation: {
                enabled: false,
            },
        },
        toolbar: {
            show: false,
        },
    },
    states: {
        hover: {
            filter: {
                type: 'none',
                value: 0,
            },
        },
    },
    legend: {
        show: true,
        showForSingleSeries: true,
        position: 'right',
        horizontalAlign: 'center',
        markers: {
            width: 30,
            height: 30,
            radius: 0,
            offsetY: 8,
            offsetX: -3,
        },
        fontSize: '16px',
        fontFamily: 'AimsunMedium',
        itemMargin: {
            vertical: -3,
        },
    },
    dataLabels: {
        enabled: false,
    },
    xaxis: {
        labels: {
            hideOverlappingLabels: true,
            style: {
                fontFamily: 'AimsunLight',
            },
        },
        title: {
            text: 'time',
            style: {
                fontSize: '18px',
                fontFamily: 'AimsunLight',
            },
        },
    },
    yaxis: {
        labels: {
            show: true,
            style: {
                fontSize: '16px',
                fontFamily: 'AimsunLight',
            },
        },
        title: {
            text: 'pattern',
            style: {
                fontSize: '18px',
                fontFamily: 'AimsunLight',
            },
        },
    },
    plotOptions: {},
    noData: {
        text: 'No data',
        align: 'center',
        verticalAlign: 'middle',
        style: {
            color: SMOKE,
            fontSize: '2.5rem',
        },
    },
}

export const lineChartOptionsInitialState: ApexCharts.ApexOptions = {
    colors: PATTERNS_PALETTE,
    chart: {
        animations: {
            enabled: false,
            animateGradually: {
                enabled: false,
            },
            dynamicAnimation: {
                enabled: false,
            },
        },
        toolbar: {
            show: false,
        },
        zoom: {
            enabled: false,
        },
    },
    legend: {
        show: true,
        position: 'right',
        horizontalAlign: 'center',
        markers: {
            width: 30,
            height: 30,
            radius: 0,
            offsetY: 8,
            offsetX: -3,
        },
        fontSize: '16px',
        fontFamily: 'AimsunMedium',
        itemMargin: {
            vertical: -3,
        },
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        width: 2,
    },
    xaxis: {
        labels: {
            show: false,
        },
        title: {
            text: 'time',
            style: {
                fontSize: '18px',
                fontFamily: 'AimsunLight',
            },
        },
    },
    yaxis: {
        forceNiceScale: true,
        axisTicks: {
            show: true,
        },
        labels: {
            show: true,
            style: {
                fontSize: '16px',
                fontFamily: 'AimsunLight',
            },
        },
        title: {
            text: 'pattern',
            style: {
                fontSize: '18px',
                fontFamily: 'AimsunLight',
            },
        },
    },
    noData: {
        text: 'No data',
        align: 'center',
        verticalAlign: 'middle',
        style: {
            color: SMOKE,
            fontSize: '2.5rem',
        },
    },
}

export const loadLineChartInitialStateWithTooltip = (
    timeZone: string,
    dateFormat: string,
    timeFormat: string,
    setLineChartOptions: (value: React.SetStateAction<ApexCharts.ApexOptions>) => void
) => {
    const dateTimeFormat = `${dateFormat} | ${timeFormat}`
    setLineChartOptions({
        ...lineChartOptionsInitialState,
        xaxis: {
            ...lineChartOptionsInitialState.xaxis,
            labels: {
                show: false,
                formatter: function (value) {
                    const dateTime = DateTime.fromMillis(+value, { zone: timeZone })
                    const date: string = dateTime.toFormat(dateTimeFormat)
                    return date
                },
            },
            title: {
                text: t(`qualityManager.${lineChartOptionsInitialState.xaxis?.title?.text}`),
                style: {
                    fontSize: '18px',
                    fontFamily: 'AimsunLight',
                },
            },
        },
        noData: {
            text: t('common.noData'),
            align: 'center',
            verticalAlign: 'middle',
            style: {
                color: SMOKE,
                fontSize: '2.5rem',
            },
        },
        tooltip: {
            followCursor: true,
            custom: options => {
                let tooltipValue = t('common.noData')

                if (options) {
                    const date = options.ctx.data.twoDSeriesX[options.dataPointIndex]

                    let tip: string = `
                        <div class="qm__tooltip">
                            <p class="qm__tooltip-text">
                                <span class="qm__tooltip-text--grey">${t('qualityManager.date')}: </span> ${date}
                            </p>
                    `

                    const initialSeries = options.w.globals.initialSeries
                    initialSeries.forEach((series: any, index: any) => {
                        let value = options.series[index][options.dataPointIndex]
                        if (!value || value === -1) {
                            value = tooltipValue
                        }
                        const name = series.name
                        tip =
                            tip +
                            `<p class="qm__tooltip-text">
                            <span class="qm__tooltip-text--grey">${name}: </span> ${value}
                            </p>`
                    })
                    tip = tip + `</div>`

                    return tip
                }
            },
        },
    })
}

export const viewModes: IQualityManagerDialogViewMode[] = [
    { label: 'heatmap' },
    { label: 'table' },
    { label: 'graph' },
    // { label: 'map' },
]

export const QmOutputTypes = new Map<string, IQMOutputType>([
    ['flowqmstations', { variable: 'qm', statistic: 'flow', object_type: 'station' }],
    ['flowr2stations', { variable: 'r2', statistic: 'flow', object_type: 'station' }],
    ['flowslopestations', { variable: 'slope', statistic: 'flow', object_type: 'station' }],
    ['speedqmstations', { variable: 'qm', statistic: 'speed', object_type: 'station' }],
    ['speedqmsections', { variable: 'qm', statistic: 'speed', object_type: 'section' }],
])
